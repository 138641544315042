import React from "react";
import { Spinner, SpinnerProps } from "@shopify/polaris";

// == Types ================================================================

interface IProps extends SpinnerProps {
  message: string;
}

// == Constants ============================================================

SpinnerMessage.defaultProps = {
  message: "",
  spinnerColor: "teal",
};

// == Component ============================================================

export function SpinnerMessage({ message, ...spinnerProps }: IProps) {
  return (
    <div className="spinner-message">
      <p>
        <Spinner {...spinnerProps} size="small" />
        <span>{message}</span>
      </p>
    </div>
  );
}

// == Styles ===============================================================
