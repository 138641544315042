import React from "react";
import { Icon, IconProps, List } from "@shopify/polaris";

// == Types ================================================================

interface IProps {
  message: string;
  details: string[];
  iconSource: IconProps["source"];
  iconColor: IconProps["color"];
  actionText: string;
  onClickAction: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

// == Constants ============================================================

IconMessage.defaultProps = {
  message: "",
  iconSource: "",
  actionText: "",
  details: [],
  iconColor: "black",
};

// == Component ============================================================

export function IconMessage({
  message,
  details,
  iconSource,
  iconColor,
  actionText,
  onClickAction,
}: IProps) {
  return (
    <div className="icon-message">
      <p>
        <Icon color={iconColor} source={iconSource} />
        <span>{message}</span>
        {actionText.length > 0 && (
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              if (typeof onClickAction === "function") {
                onClickAction(event);
              }
            }}
          >
            {actionText}
          </a>
        )}
      </p>
      {details.length > 0 && (
        <List type="bullet">
          {details.map((msg, index) => (
            <List.Item key={index}>{msg}</List.Item>
          ))}
        </List>
      )}
    </div>
  );
}

// == Styles ===============================================================
