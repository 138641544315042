import React from "react";
import { Banner, BannerProps } from "@shopify/polaris";

// == Types ================================================================

interface IProps extends BannerProps {
  message: string;
}

// == Constants ============================================================

ErrorBanner.defaultProps = {
  status: "critical",
  message: "Something went wrong.",
};

// == Component ============================================================

export function ErrorBanner(props: IProps) {
  const { message, ...bannerProps } = props;

  return <Banner {...bannerProps}>{message}</Banner>;
}

// == Styles ===============================================================
