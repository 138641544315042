import React, { useState } from "react";
// import createApp from "@shopify/app-bridge";
// import { Redirect } from "@shopify/app-bridge/actions";
import { SpinnerMessage } from "@components/SpinnerMessage";
import { IconMessage } from "@components/IconMessage";
// import { REQUIRED_ACCESS_SCOPES } from "@utils/api/auth";
// import { fetchRequest, SHOPIFY_API_KEY } from "@utils";
import { fetchRequest } from "@utils";

// == Types ================================================================

interface IProps {
  toggleTokenStatusError: (nextvalue?: boolean) => void;
  shopOrigin: string;
}

interface IStatusState {
  success: boolean;
  pending: boolean;
}

// == Constants ============================================================

// == Functions ============================================================

// == Component ============================================================

export function ShopifyTokenStatus({ toggleTokenStatusError }: IProps) {
  const [tokenStatus, setTokenStatus] = useState<IStatusState>({
    success: true,
    pending: false,
  });
  // const [tokenStatus, setTokenStatus] = useState<IStatusState>({
  //   success: false,
  //   pending: true,
  // });

  const validateToken = async () => {
    try {
      const { ok } = await fetchRequest("/auth/validate_token", { method: "GET" });
      if (ok) toggleTokenStatusError(false);
      setTokenStatus({ success: ok, pending: false });
    } catch (error) {
      toggleTokenStatusError(true);
      setTokenStatus({ success: false, pending: false });
    }
  };

  // useEffect(() => {
  //   const validateToken = async () => {
  //     try {
  //       const { ok } = await fetchRequest("/auth/validate_token", { method: "GET" });
  //       if (ok) toggleTokenStatusError(false);
  //       setTokenStatus({ success: ok, pending: false });
  //     } catch (error) {
  //       toggleTokenStatusError(true);
  //       setTokenStatus({ success: false, pending: false });
  //     }
  //   };
  //   validateToken();
  // }, [toggleTokenStatusError]);

  const { pending, success } = tokenStatus;
  return pending ? (
    <SpinnerMessage message={"Verifying Shopify access token\u2026"} />
  ) : (
    <IconMessage
      // actionText="Refresh token"
      actionText="Validate token"
      iconColor={success ? "green" : "red"}
      iconSource={success ? "checkmark" : "alert"}
      message={`"Your Shopify access token is ${success ? "valid" : "invalid"}."`}
      onClickAction={() => {
        validateToken();
        // if (!shopOrigin) return;
        // const url = `https://c81accd3.ngrok.io/auth/callback`;
        // const permissionUrl = `/oauth/authorize?client_id=${SHOPIFY_API_KEY}&scope=${REQUIRED_ACCESS_SCOPES.join(
        //   ",",
        // )}&redirect_uri=${url}`;
        // console.log("window.top === window.self", window.top === window.self);
        // console.log("permissionUrl", permissionUrl);
        // if (window.top === window.self) {
        //   window.location.assign(url);
        // } else {
        //   const app = createApp({
        //     apiKey: SHOPIFY_API_KEY,
        //     shopOrigin,
        //   });
        //   Redirect.create(app).dispatch(Redirect.Action.ADMIN_PATH, permissionUrl);
        // }
      }}
    />
  );
}

// == Styles ===============================================================
