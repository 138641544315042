import { Bugsnag } from "@utils/Bugsnag";

export const SHOPIFY_APP_NAME = process.env.SHOPIFY_APP_NAME || "";
export const SHOPIFY_APP_URL_NAME = process.env.SHOPIFY_APP_URL_NAME || "";
export const SHOPIFY_API_KEY = process.env.SHOPIFY_API_KEY || "";

export const APP_HOST_URL = process.env.APP_HOST_URL || "";
export const FLIPGIVE_SHOPIFY_API_URL = "https://api-flipgive.test/shopify";
export const FLIPGIVE_INTENT_PATH = "/intents";

if (!SHOPIFY_API_KEY) {
  const message = "Missing Shopify API key";
  Bugsnag.notify(new Error(message));
  console.warn(message); // eslint-disable-line no-console
}

if (!APP_HOST_URL) {
  const message = "Missing APP_HOST_URL env";
  Bugsnag.notify(new Error(message));
  console.warn(message); // eslint-disable-line no-console
}

export const IS_DEVELOPMENT = process.env.NODE_ENV === "development";
export const IS_STAGING = process.env.NODE_ENV === "staging";
export const IS_PRODUCTION = process.env.NODE_ENV === "production";
export const IS_API = !process.browser;
export const IS_CLIENT = process.browser;
