// Paths
export const OAUTH_START_PATH = "/auth";
export const OAUTH_INLINE_PATH = "/auth/inline";
export const ENABLE_COOKIES_PATH = "/auth/enable_cookies";

// Cookie Names
export const TOP_LEVEL_OAUTH_COOKIE_NAME = "shopifyTopLevelOAuth";
export const TEST_COOKIE_NAME = "shopifyTestCookie";

const DEFAULT_MYSHOPIFY_DOMAIN = "myshopify.com";
export const SHOPIFY_VALIDATION_REGEX = new RegExp(
  `^[a-z0-9][a-z0-9\\-]*[a-z0-9]\\.${DEFAULT_MYSHOPIFY_DOMAIN}$`,
  "i",
);
export const SHOPIFY_ACCESS_MODE = "offline";

// Required Scopes
const READ_ORDERS = "read_orders" as const;
const WRITE_ORDERS = "write_orders" as const;
const READ_SCRIPT_TAGS = "read_script_tags" as const;
const WRITE_SCRIPT_TAGS = "write_script_tags" as const;
export const REQUIRED_ACCESS_SCOPES = [
  READ_ORDERS,
  WRITE_ORDERS,
  READ_SCRIPT_TAGS,
  WRITE_SCRIPT_TAGS,
];

export enum AuthErrorsEnum {
  ShopParamMissing = "Expected a valid shop query parameter",
  InvalidHmac = "HMAC validation failed",
  AccessTokenFetchFailure = "Could not fetch access token",
  NonceMatchFailed = "Request origin could not be verified",
}

// Cookie Options
const FIVE_YEARS_FROM_NOW = new Date(new Date().getTime() + 1000 * 3600 * 24 * 365 * 5);
export const ACCESS_TOKEN_COOKIE_OPTIONS = {
  httpOnly: true,
  secure: true,
  path: "/",
  expires: FIVE_YEARS_FROM_NOW,
  encrypt: true,
};
export const SHOP_ORIGIN_COOKIE_OPTIONS = {
  httpOnly: true,
  secure: true,
  path: "/",
  expires: FIVE_YEARS_FROM_NOW,
};
