import "isomorphic-fetch";
import { IS_DEVELOPMENT } from "@utils/constants";

// == Types ================================================================

interface IJsonResponse<TResponseType> {
  data?: TResponseType;
  ok: boolean;
  status: number;
  statusText: string;
}

// == Constants ============================================================

const DISABLE_DEVELOPMENT_LOGGING = false;

// == Functions ============================================================

/* eslint-disable no-console */
const logRequestForDebugging = (endpoint: string, options: RequestInit) => {
  if (!IS_DEVELOPMENT || DISABLE_DEVELOPMENT_LOGGING) return;

  console.log(`===========${options.method} ${endpoint} REQUEST ==================\n`);
  if (typeof options.body === "string") {
    console.log(`Body: ${options.body.substr(0, 500)}\n`);
    console.log("==================================================================\n\n");
  }
};

const logResponseForDebugging = (
  endpoint: string,
  options: RequestInit,
  response: Response,
  responseBody = {},
) => {
  if (!IS_DEVELOPMENT || DISABLE_DEVELOPMENT_LOGGING) return;

  console.log(`===========${options.method} ${endpoint} RESPONSE ================\n`);
  console.log(`
    Status ${response.status}: ${response.statusText}\n
    Headers: ${JSON.stringify(options.headers)}\n
    Body: ${JSON.stringify(responseBody)}\n
  `);
  console.log("==================================================================\n\n");
};
/* eslint-enable no-console */

// == Exports ==============================================================

export async function fetchRequest<TResponseType>(
  endpoint: string,
  options: RequestInit = {},
): Promise<IJsonResponse<TResponseType>> {
  if (options.body && typeof options.body !== "string") {
    options.body = JSON.stringify(options.body);
  }
  logRequestForDebugging(endpoint, options);
  const response = await fetch(endpoint, options);
  const data = await response.json();
  logResponseForDebugging(endpoint, options, response, data);
  return {
    data,
    ok: response.ok,
    status: response.status,
    statusText: response.statusText,
  };
}
