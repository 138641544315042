import React, { useMemo, useState, useEffect } from "react";
import { EmptyState } from "@shopify/polaris";
import { Provider, Loading } from "@shopify/app-bridge-react";
import Cookies from "js-cookie";
import { queryParams, SHOPIFY_API_KEY, Bugsnag, renderIf } from "@utils";

// == Types ================================================================

interface IProps {
  children: (shopOrigin: string) => React.ReactNode;
}

// == Constants ============================================================

// == Functions ============================================================

// == Component ============================================================

export function ShopifyBridgeProvider({ children }: IProps) {
  const [requestValidation, setRequestValidation] = useState({ isValid: false, pending: true });
  const { isValid, pending } = requestValidation;

  const shopOrigin = useMemo(() => {
    if (typeof window === "undefined") return "";

    const { shop: shopOriginParam } = queryParams(location.search);
    if (shopOriginParam && typeof shopOriginParam === "string") return shopOriginParam;
    return "";
  }, []);

  useEffect(() => {
    if (!shopOrigin) return;
    if (Cookies.get("shopOrigin") === shopOrigin) return;

    Cookies.set("shopOrigin", shopOrigin);
  }, [shopOrigin]);

  useEffect(() => {
    const { hmac } = queryParams(location.search);
    if (!hmac) {
      setRequestValidation({ isValid: false, pending: false });
      return;
    }

    fetch(`/api/auth/validate_request?${location.search.substr(1)}`, { method: "GET" })
      .then((response) => {
        setRequestValidation({ isValid: response.ok, pending: false });
      })
      .catch((error) => {
        setRequestValidation({ isValid: false, pending: false });
        Bugsnag.notify(error);
      });
  }, []);

  return (
    <Provider
      config={{
        apiKey: SHOPIFY_API_KEY,
        shopOrigin,
        forceRedirect: true,
      }}
    >
      {renderIf(pending)(<Loading />)}
      {renderIf(isValid && !pending)(children(shopOrigin))}
      {renderIf(!isValid && !pending)(
        <EmptyState
          action={{
            content: "Contact FlipGive Support",
            url: "https://www.flipgive.com/pages/contact",
            external: true,
          }}
          heading="There was an error loading the app."
          image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
        >
          Please contact FlipGive support and we will look into the issue.
        </EmptyState>,
      )}
    </Provider>
  );
}

// == Styles ===============================================================
