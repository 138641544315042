import React from "react";
import BugsnagClient from "@bugsnag/js";
import BugsnagReact from "@bugsnag/plugin-react";

const Bugsnag = BugsnagClient({
  // If BUGSNAG_API_KEY isn't set, use NODE_ENV so "next build" still works in development
  apiKey: process.env.BUGSNAG_API_KEY || process.env.NODE_ENV,
  appType: process.browser ? "CLIENT" : "API",
  appVersion: "1.0.0",
  notifyReleaseStages: ["production"],
  releaseStage: process.env.NODE_ENV,
});
Bugsnag.use(BugsnagReact, React);

export { Bugsnag };
