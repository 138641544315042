import { ReactNode } from "react";

// == Types ================================================================

type TFunction = () => ReactNode;

// == Constants ============================================================

// == Functions ============================================================

// == Exports ==============================================================

export const renderIf = (predicate: boolean) => (returnValue: ReactNode | TFunction) => {
  return predicate ? (typeof returnValue === "function" ? returnValue() : returnValue) : null;
};
