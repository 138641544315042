import React, { useEffect, useState } from "react";
import { SpinnerMessage } from "@components/SpinnerMessage";
import { IconMessage } from "@components/IconMessage";
import { useToggle } from "@hooks";
import { fetchRequest } from "@utils";

// == Types ================================================================

interface IProps {
  toggleWebhooksStatusError: (nextvalue?: boolean) => void;
}

interface IStatusState {
  success: boolean;
  pending: boolean;
}

// == Constants ============================================================

// == Functions ============================================================

// == Component ============================================================

export function WebhooksStatus({ toggleWebhooksStatusError }: IProps) {
  const [webhookStatus, setWebhooksStatus] = useState<IStatusState>({
    success: false,
    pending: true,
  });

  const [isInstallingWebhooks, toggleIsInstallingWebhooks] = useToggle(false);

  useEffect(() => {
    const getWebhooksInstallStatus = async () => {
      try {
        const { ok } = await fetchRequest("/install/webhooks/status", { method: "GET" });
        if (ok) toggleWebhooksStatusError(false);
        setWebhooksStatus({ success: ok, pending: false });
      } catch (error) {
        toggleWebhooksStatusError(true);
        setWebhooksStatus({ success: false, pending: false });
      }
    };
    getWebhooksInstallStatus();
  }, [toggleWebhooksStatusError]);

  const installWebhooks = async () => {
    if (isInstallingWebhooks) return;
    try {
      toggleIsInstallingWebhooks();
      const { ok, data } = await fetchRequest("/install/webhooks", { method: "GET" }); // TODO: Add response type
      if (ok && data && data.installSuccess) setWebhooksStatus({ success: true, pending: false });
      toggleIsInstallingWebhooks();
    } catch (error) {
      toggleWebhooksStatusError();
      toggleIsInstallingWebhooks();
    }
  };

  const { pending, success } = webhookStatus;
  return pending || isInstallingWebhooks ? (
    <SpinnerMessage
      message={
        isInstallingWebhooks
          ? "Please wait while we update the webhooks\u2026"
          : "Verifying webhooks\u2026"
      }
    />
  ) : (
    <IconMessage
      actionText="Install/update webhooks"
      iconColor={success ? "green" : "red"}
      iconSource={success ? "checkmark" : "alert"}
      message={
        success
          ? "All webhooks are installed."
          : "One or more webhooks need to be installed or updated."
      }
      onClickAction={installWebhooks}
    />
  );
}

// == Styles ===============================================================
